import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "stamp-logo.png" }) {
        childImageSharp {
          fixed(width: 162, height: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Container>
      <div className="footer-top">
        <div className="footer-top-item">
          <Img fixed={data.file.childImageSharp.fixed} className="logo" />
        </div>
        <div className="footer-top-item">
          <Link className="footer-item" to="/">
            Bristol Massage
          </Link>
          <Link className="footer-item" to="/">
            Massage Bristol
          </Link>
          <Link className="footer-item" to="/deep-tissue-massage/">
            Deep Tissue Massages
          </Link>
          <Link className="footer-item" to="/swedish-massage/">
            Swedish Massages
          </Link>
          <Link className="footer-item" to="/sports-massage/">
            Sports Massages
          </Link>
        </div>
        <div className="footer-top-item">
          <Link className="footer-item" to="/thai-massage/">
            Thai Massages
          </Link>
          <Link className="footer-item" to="/couples-massage/">
            Couples Massages
          </Link>
          <Link className="footer-item" to="/hot-stone-massage/">
            Hot Stone Massages
          </Link>
          <Link className="footer-item" to="/privacy/">
            Privacy
          </Link>
          <Link className="footer-item" to="/">
            Contact
          </Link>
        </div>
        <div className="footer-top-item email">
          <h6>Get in touch</h6>
          james@massagesbristol.co.uk
        </div>
      </div>

      <div className="footer-bottom">
        <span className="copyright">Copyright 2021 © All Rights Reserved</span>
        <span>Terms & Conditions</span>
      </div>
    </Container>
  )
}

export default Footer

const Container = styled.div`
  padding: 3em 10px 2em 10px;
  background: #1d2b3f;
  color: #feede2;
  h6 {
    font-family: "Vidaloka", serif;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0.8em;
  }
  a {
    padding: 0.4em 0;
    color: inherit;
  }
  .footer-top {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .footer-top-item {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
  .footer-bottom {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4em;
    span {
      margin: 0.5em 0;
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 840px) {
    .footer-top {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .email {
      margin-top: 1.5em;
    }
    .footer-top-item {
      align-items: center;
    }
    .logo {
      margin-bottom: 1.5em;
    }
    .footer-bottom {
      margin-top: 2em;
    }
    a {
      font-size: 15px;
    }
    h6 {
      margin-bottom: 0.5em;
    }
  }
`

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Container = styled.div`
  nav {
    width: 0em;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    transition: width 0.8s ease;
    background: #1d2b3f;
    white-space: nowrap;
    overflow: hidden;
    z-index: 20000;
    color: #fff;
    max-width: 375px;
  }
  .sidebar-content {
    width: 90%;
    height: 100%;
    margin: 100px auto;
    font-size: 1.2em;
    letter-spacing: 2px;
    display: flex;
    flex-direction: column;
  }
  .SideBar-active {
    width: 100vw;
  }
  a {
    font-size: 14px;
    margin: 0px 20px;
    font-weight: 600;
    cursor: pointer;
    padding: 15px 0px;
    transition: all 0.3s ease;
    color: inherit;
    &:hover {
      color: #f7e4bf;
    }
  }
  .close {
    float: right;
    margin-right: 2em;
    margin-top: 1.5em;
    cursor: pointer;
    fill: #fff;
  }
`

const SideBar = ({ SideBarIsActive, toggleSideBar }) => {
  return (
    <Container>
      <nav className={SideBarIsActive ? `SideBar-active` : ``}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 24 24"
          className="close"
          onClick={() => toggleSideBar(!SideBarIsActive)}
        >
          <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
        </svg>
        <div className="sidebar-content">
          <Link className="footer-item" to="/">
            Home
          </Link>
          <Link className="footer-item" to="/">
            Massage Bristol
          </Link>
          <Link className="footer-item" to="/deep-tissue-massage/">
            Deep Tissue Massages
          </Link>
          <Link className="footer-item" to="/swedish-massage/">
            Swedish Massages
          </Link>
          <Link className="footer-item" to="/sports-massage/">
            Sports Massages
          </Link>
          <Link className="footer-item" to="/thai-massage/">
            Thai Massages
          </Link>
          <Link className="footer-item" to="/couples-massage/">
            Couples Massages
          </Link>
          <Link className="footer-item" to="/hot-stone-massage/">
            Hot Stone Massages
          </Link>
          <Link className="footer-item" to="/">
            Get Listes
          </Link>
          <Link className="footer-item" to="/">
            Contact
          </Link>
        </div>
      </nav>
    </Container>
  )
}

export default SideBar

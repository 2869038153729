import React, { useState } from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Hamburger from "./Hamburger"
import SideBar from "./SideBar"

const Header = () => {
  const [SideBarIsActive, toggleSideBar] = useState(false)
  const navLinks = [
    {
      title: "Deep Tissue Massage",
      url: "/deep-tissue-massage/",
    },
    {
      title: "Swedish Massage",
      url: "/swedish-massage/",
    },
    {
      title: "Sports Massage",
      url: "/sports-massage/",
    },
    {
      title: "Thai Massage",
      url: "/thai-massage/",
    },
    {
      title: "Couples Massage",
      url: "/couples-massage/",
    },
    {
      title: "Hot Stone Massage",
      url: "/hot-stone-massage/",
    },
  ]

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <nav className="header-wrapper">
        <Link to="/" className="logo-link">
          i
          <Img fluid={data.file.childImageSharp.fluid} className="logo" />
        </Link>
        <div className="right-nav">
          <span></span>
          <div className="droppable">
            <span className="dropdown">
              massage type{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="16"
                viewBox="0 0 512 512"
              >
                <polyline
                  points="112 268 256 412 400 268"
                  style={{
                    fill: "none",
                    stroke: "#000",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "48px",
                  }}
                />
                <line
                  x1="256"
                  y1="392"
                  x2="256"
                  y2="100"
                  style={{
                    fill: "none",
                    stroke: "#000",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "48px",
                  }}
                />
              </svg>
            </span>
            <div className="mega-menu">
              {navLinks.map(navlink => (
                <Link className="cat-link" to={navlink.url} key={navlink.url}>
                  {navlink.title}
                </Link>
              ))}
            </div>
          </div>
          <span></span>
          <span
            className="hamburger"
            onClick={() => toggleSideBar(!SideBarIsActive)}
          >
            <Hamburger />
          </span>
        </div>
      </nav>
      <SideBar
        SideBarIsActive={SideBarIsActive}
        toggleSideBar={toggleSideBar}
      />
    </Container>
  )
}
export default Header

const Container = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  position: absolute;
  top: 0;
  width: 100%;
  .header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    z-index: 2;
    padding: 10px 2em;
    transition: all 0.5s;
    max-width: 1500px;
    position: absolute;
    margin: auto;
    @media only screen and (min-width: 1500px) {
      left: 50%;
      margin-left: -750px;
    }
  }
  .logo {
    width: 180px;
    height: 60px;
  }
  .logo-link {
    display: flex;
    color: #000;
    font-size: 1px;
  }
  .droppable {
    position: relative;
    svg {
      position: relative;
      transform: translateY(3px);
    }
  }
  span {
    position: relative;
    font-weight: 700;
    font-size: 15px;
    padding: 10px 0;
    margin: 0 1.5em;
    cursor: pointer;
    font-family: "Karla", sans-serif;
    color: #1d2b3f;
  }
  .right-nav {
    display: flex;
    align-items: center;
  }
  .mega-menu {
    border-radius: 7px;
    opacity: 0;
    z-index: -1;
    position: absolute;
    height: 0px;
    overflow: hidden;
    top: -1em;
    right: 0em;
    white-space: nowrap;
    transition: all 0.4s ease;
    background: #fff;
    display: flex;
    flex-direction: column;
    background: #1d2b3f;
  }
  .droppable:hover,
  .dropdown:hover {
    .mega-menu {
      padding: 10px;
      opacity: 1;
      top: 1.85em;
      z-index: 3;
      height: auto;
    }
  }
  .cat-link {
    font-family: "Karla", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    width: 100%;
    font-size: 14px;
    margin: 0;
    padding: 15px 80px 15px 20px;
    transition: all 0.3s ease;
    &:hover {
      color: #f7e4bf;
    }
  }
  .hamburger {
    display: none;
    margin-right: -1.5em;
    margin-top: 2px;
  }
  @keyframes animate {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  }
  @media only screen and (max-width: 840px) {
    height: 60px;
    background-color: #ffffff;
    .logo {
      width: 120px;
      height: 40px;
    }
    span {
      display: none;
    }
    .hamburger {
      display: block;
    }
  }
`
